import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const root = css`
  background-color: ${color('black', 1)};
`;

const wrapper = css`
  opacity: 0.8;

  border-top: 1px solid ${color('borderLight', 9)};
  z-index: var(--level-3);
  width: 100%;
  height: 180px;

  display: flex;
  justify-content: space-between;
  padding-top: var(--spacing-xxxlrg);
`;

const navLink = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('small')};
  ${lineHeight('small')};
  color: ${color('white', 11)};
  text-decoration: none;

  margin: 0 var(--spacing-xlrg);

  width: fit-content;
  padding-bottom: var(--spacing-med);
  position: relative;
  &::after {
    content: '';
    width: 0rem;
    transition: width 0.5s ease;
    margin-top: 20px;
  }
  &:hover {
    transition: 0.5s;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      height: 2px;
      width: 100%;
      background-color: ${color('primary', 5)};
    }
  }
`;

const linkWrapper = css`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.medium} {
    flex-direction: row;
  }
`;

const socialWrapper = css`
  display: flex;
  flex-direction: row;
  float: left;
  padding-top: 0;

  @media ${breakpoints.medium} {
    float: right;
    padding-top: var(--spacing-med);
  }
`;

const socailLink = css`
  width: fit-content;
  padding-bottom: var(--spacing-med);
  position: relative;
  margin-left: var(--spacing-xlrg);
  margin-right: 0;

  @media ${breakpoints.medium} {
    margin-right: var(--spacing-xlrg);
    margin-left: 0;
  }

  &:hover {
    svg {
      path {
        fill: ${color('primary', 5)};
      }
    }
  }
`;

export const footerStyles = {
  root,
  wrapper,
  navLink,
  linkWrapper,
  socialWrapper,
  socailLink,
};
