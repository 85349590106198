import React from 'react';
import { accordionStyles } from 'style';

interface Props {
  id: number;
  title: string;
  description: string;
  handleChangeAccordion: (id: number) => void;
  isOpen: boolean;
}

export const Accordion: React.FC<Props> = ({
  id,
  title,
  description,
  handleChangeAccordion,
  isOpen,
}) => {
  return (
    <div css={accordionStyles.accordion} onClick={chnageAccordion}>
      <div css={accordionStyles.iconWrapper}>
        <div css={accordionStyles.horizontalIcon}></div>
        <div css={accordionStyles.verticalIcon(isOpen)}></div>
      </div>
      <div css={accordionStyles.accordionHeader}>
        <span css={accordionStyles.title}>{title}</span>
        <div css={accordionStyles.accordionContent(isOpen)}>
          <p css={accordionStyles.description}>{description}</p>
        </div>
        <div css={accordionStyles.line} />
      </div>
    </div>
  );

  function chnageAccordion() {
    handleChangeAccordion(id);
  }
};
