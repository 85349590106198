import { Container } from 'components';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { footerStyles, Routes } from '..';
import { Logo } from 'assets/icons/Logo';
import { InstagramIcon } from 'assets/icons/InstagramIcon';
import { TwitterIcon } from 'assets/icons/TwitterIcon';

export const Footer: React.FC = () => {
  const [logoHover, setLogoHover] = useState<boolean>(false);

  return (
    <footer css={footerStyles.root}>
      <Container>
        <div css={footerStyles.wrapper}>
          <Link
            to={Routes.Home}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            onClick={handleLeave}
          >
            <Logo color="#FFFFFF" hover={logoHover} />
          </Link>
          <div>
            <div css={footerStyles.linkWrapper}>
              {/*  <Link to="/" css={footerStyles.navLink}>
              Data & Privacy Policy
            </Link> */}
              <Link to={Routes.Faq} css={footerStyles.navLink}>
                FAQ
              </Link>
              <a
                href="https://donorbox.org/makerhand"
                target="_blank"
                css={footerStyles.navLink}
              >
                Donate
              </a>
              <Link to={Routes.Contact} css={footerStyles.navLink}>
                Contact
              </Link>
            </div>
            <div css={footerStyles.socialWrapper}>
              <a
                href="https://www.instagram.com/_makerhand_"
                target="_blank"
                css={footerStyles.socailLink}
              >
                <InstagramIcon />
              </a>
              <a
                href="https://twitter.com/_makerhand_"
                target="_blank"
                css={footerStyles.socailLink}
              >
                <TwitterIcon />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );

  function handleEnter() {
    setLogoHover(true);
  }

  function handleLeave() {
    setLogoHover(false);
  }
};
