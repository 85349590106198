import { Link } from 'gatsby';
import { Button } from 'components';
import React from 'react';
import { navigationStyles, Routes } from '..';
import { MobileNavigation } from '.';

interface NavigationProps {
  isNavOpen: boolean;
  onClose: VoidFunction;
}

export const Navigation: React.FC<NavigationProps> = ({
  isNavOpen,
  onClose,
}) => {
  if (isNavOpen) return <MobileNavigation onClose={onClose} />;

  return (
    <nav css={navigationStyles.root}>
      <ul css={navigationStyles.list}>
        {/*     <li>
          <Link
            to={Routes.Community}
            css={navigationStyles.navLink}
            onClick={onClose}
            activeClassName="active"
          >
            Community
          </Link>
        </li> */}
        <li>
          <Link
            to={Routes.Faq}
            css={navigationStyles.navLink}
            onClick={onClose}
            activeClassName="active"
          >
            FAQ
          </Link>
        </li>
        <li>
          <Link
            to={Routes.Contact}
            css={navigationStyles.navLink}
            onClick={onClose}
            activeClassName="active"
          >
            Contact
          </Link>
        </li>
        <li>
          <Button
            variant="light"
            size="sml"
            href="https://donorbox.org/makerhand"
            onClick={onClose}
            additionalClasses={navigationStyles.donate}
          >
            Donate
          </Button>
        </li>
      </ul>
    </nav>
  );
};
