import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { headerStyles, Routes } from '..';
import { Logo } from 'assets/icons/Logo';
import { useLocation } from '@reach/router';
import { Navigation } from '.';
import { Container } from 'components';

export const Header: React.FC = () => {
  const location = useLocation();

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [logoHover, setLogoHover] = useState<boolean>(false);

  function closeNavigation() {
    setIsNavOpen(false);
    setLogoHover(false);
  }

  function mutateNavigation() {
    setIsNavOpen(!isNavOpen);
  }

  let scroll = 10;

  useEffect(() => {
    window.onscroll = () => {
      const headerId = document.getElementById('header');

      if (!headerId) return;

      if (isNavOpen) {
        headerId.style.position = 'sticky';
        headerId.style.top = '0';
        headerId.style.zIndex = '4';
        return;
      }

      if (scroll >= window.scrollY && window.scrollY > 0) {
        if (!isNavOpen) {
          headerId.style.position = 'sticky';
          headerId.style.top = '0';
        }
      } else {
        headerId.style.top = '-100px';
      }

      scroll = window.scrollY;
    };
  }, [isNavOpen]);

  return (
    <header
      css={[
        headerStyles.root,
        location.pathname === '/' && headerStyles.grayBg,
      ]}
      id="header"
    >
      <Container>
        <div css={headerStyles.wrapper}>
          <Link
            to={Routes.Home}
            css={headerStyles.logo}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            onClick={closeNavigation}
          >
            <Logo hover={logoHover} />
          </Link>
          <Navigation isNavOpen={isNavOpen} onClose={closeNavigation} />
          <button onClick={mutateNavigation} css={headerStyles.navOpen}>
            <div
              css={[
                isNavOpen ? headerStyles.lineDown : null,
                headerStyles.hamburger,
              ]}
            />
            <div
              css={[
                isNavOpen ? headerStyles.lineDissapear : null,
                headerStyles.hamburger,
              ]}
            />
            <div
              css={[
                isNavOpen ? headerStyles.lineUp : null,
                headerStyles.hamburger,
              ]}
            />
          </button>
        </div>
      </Container>
    </header>
  );

  function handleEnter() {
    setLogoHover(true);
  }

  function handleLeave() {
    setLogoHover(false);
  }
};
