import { css } from '@emotion/react';
import { color } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

const pageWrapper = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  margin: 0 auto;

  transition: all 0.5s;
`;

const container = css`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  transition: all 0.5s;
  max-width: 1440px;

  padding: 0 20px;

  @media ${breakpoints.medium} {
    padding: 0 20px;
  }

  @media ${breakpoints.large} {
    padding: 0 40px;
  }
`;

const root = css`
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const logo = (isHovered: boolean) => css`
  ${isHovered &&
  `
path {
  fill: ${color('primary', 5)}
}`}
`;

export const layoutStyles = { root, pageWrapper, container, logo };
