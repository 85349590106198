import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const title = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('medium')};
  ${lineHeight('medium')};

  @media ${breakpoints.medium} {
    ${fontSize('large')};
    ${lineHeight('large')};
  }

  color: ${color('text', 2)};
`;

const description = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('base')};
  ${lineHeight('base')};

  color: ${color('textLight', 3)};
`;

const accordion = css`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: calc(var(--unit) * 4) 0;
  display: flex;
  flex-direction: row;
`;

const accordionHeader = css`
  display: flex;
  flex-direction: column;
`;

const accordionContent = (isOpen: boolean) => css`
  cursor: auto;
  padding-top: calc(var(--unit) * 2);
  overflow: hidden;
  transition: all 0.5s linear;
  max-height: 0;
  display: flex;
  flex-direaction: row;

  ${isOpen && `max-height:500px;`}
`;

const iconWrapper = css`
  position: relative;
  height: 30px;
  top: 7px;
  margin-right: 62px;
`;

const horizontalIcon = css`
  position: absolute;
  background-color: black;
  width: 20px;
  height: 1px;
  top: 10px;
`;

const verticalIcon = (isOpen: boolean) => css`
  position: absolute;
  background-color: black;
  width: 1px;
  top: 0;
  left: 10px;
  height: 20px;

  transition: all 0.5s ease-in-out;
  ${isOpen && `transform: rotate(90deg);`}
`;

const line = css`
  width: 100%;
  height: 1px;
  margin-top: var(--spacing-med);
  background-color: hsl(var(--color-borderLight-9));
`;

export const accordionStyles = {
  accordion,
  accordionHeader,
  iconWrapper,
  horizontalIcon,
  verticalIcon,
  title,
  accordionContent,
  description,
  line,
};
