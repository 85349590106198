import * as React from 'react';
import { loadingStyles } from 'style';

interface Props {
  isLoading: boolean;
  inline?: boolean;
}

export const Loading: React.FC<Props> = ({ children, isLoading }) =>
  isLoading ? <div css={loadingStyles.root}></div> : <>{children}</>;
