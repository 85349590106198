import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints, grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const root = css`
  ${grid.config.cardGrid};
  padding: var(--spacing-xxxlrg) 0;

  @media ${breakpoints.medium} {
    padding: var(--spacing-huge) 0;
  }
`;

const content = css`
  width: 100%;
  margin: 0 auto;

  padding-top: 30px;

  @media ${breakpoints.medium} {
    padding-top: 0;
  }
`;

const title = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('xLarge')};
  ${lineHeight('xLarge')};

  @media ${breakpoints.small} {
    ${fontSize('xxLarge')};
    ${lineHeight('xxLarge')};
  }

  @media ${breakpoints.custom1024} {
    ${fontSize('xxxLarge')};
    ${lineHeight('xxxLarge')};
  }

  color: ${color('text', 2)};
`;

export const faqStyles = {
  root,
  content,
  title,
};
