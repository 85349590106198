import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';
import { headerStyles, mobileNavigationStyles, Routes } from '..';
import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';

interface MobileNavigationProps {
  onClose: VoidFunction;
}

export const MobileNavigation: React.FC<MobileNavigationProps> = ({
  onClose,
}) => {
  const location = useLocation();

  return (
    <div
      css={[
        mobileNavigationStyles.root,
        location.pathname === '/' && headerStyles.grayBg,
      ]}
    >
      <nav>
        <ul css={mobileNavigationStyles.list}>
          {/*       <InView>
            {({ ref, inView }) => (
              <motion.li
                ref={ref}
                initial={{ opacity: 0, y: '-20px' }}
                animate={{ opacity: inView ? 1 : 0, y: 0 }}
                transition={{ ease: 'linear', duration: 0.5, delay: 0.2 }}
                css={mobileNavigationStyles.link}
              >
                <Link
                  to={Routes.Community}
                  onClick={onClose}
                  css={mobileNavigationStyles.navLink}
                  activeClassName="active"
                >
                  Community
                </Link>
              </motion.li>
            )}
          </InView> */}
          <InView>
            {({ ref, inView }) => (
              <motion.li
                ref={ref}
                initial={{ opacity: 0, y: '-20px' }}
                animate={{ opacity: inView ? 1 : 0, y: 0 }}
                transition={{ ease: 'linear', duration: 0.5, delay: 0.4 }}
                css={mobileNavigationStyles.link}
              >
                <Link
                  to={Routes.Faq}
                  onClick={onClose}
                  activeClassName="active"
                  css={mobileNavigationStyles.navLink}
                >
                  FAQ
                </Link>
              </motion.li>
            )}
          </InView>
          <InView>
            {({ ref, inView }) => (
              <motion.li
                ref={ref}
                initial={{ opacity: 0, y: '-20px' }}
                animate={{ opacity: inView ? 1 : 0, y: 0 }}
                transition={{ ease: 'linear', duration: 0.5, delay: 0.6 }}
                css={mobileNavigationStyles.link}
              >
                <Link
                  to={Routes.Contact}
                  onClick={onClose}
                  activeClassName="active"
                  css={mobileNavigationStyles.navLink}
                >
                  Contact
                </Link>
              </motion.li>
            )}
          </InView>
          <InView>
            {({ ref, inView }) => (
              <motion.li
                ref={ref}
                initial={{ opacity: 0, y: '-20px' }}
                animate={{ opacity: inView ? 1 : 0, y: 0 }}
                transition={{ ease: 'linear', duration: 0.5, delay: 0.8 }}
                css={mobileNavigationStyles.link}
              >
                <a
                  href="https://donorbox.org/makerhand"
                  target="_blank"
                  onClick={onClose}
                  css={mobileNavigationStyles.navLink}
                >
                  Donate
                </a>
              </motion.li>
            )}
          </InView>
        </ul>
      </nav>
    </div>
  );
};
