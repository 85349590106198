import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

const root = (isActive: boolean) => css`
  width: 80px;
  height: 3px;
  background: hsl(var(--color-primary-5));
  transition: all 0.5s;

  margin-right: 32px;

  ${isActive && `width: 80px;`}

  display: none;

  @media ${breakpoints.medium} {
    display: block;
  }
`;

const content = css`
  width: 80px;
  position: relative;
  background-color: transparent;
`;

const line = (isActive: boolean) => css`
  position: absolute;
  height: 3px;
  background-color: hsl(var(--color-primary-5));
  top: 0;
  left: -32px;

  ${isActive && `animation: width .5s 1 forwards;`}

  @keyframes width {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
`;

export const horizontalLineStyles = { root, content, line };
