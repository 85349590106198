import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const root = css`
  padding-top: var(--spacing-huge);
  padding-bottom: var(--spacing-xlrg);

  @media ${breakpoints.custom970} {
    padding-top: 120px;
    padding-bottom: var(--spacing-xxxlrg);
  }
`;

const pageTitle = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('xLarge')};
  ${lineHeight('xLarge')};

  color: ${color('text', 2)};

  @media ${breakpoints.medium} {
    ${fontSize('xxLarge')};
    ${lineHeight('xxLarge')};
  }

  @media ${breakpoints.custom970} {
    max-width: 50%;

    ${fontSize('xxxLarge')};
    ${lineHeight('xxxLarge')};
  }
`;

const chooseWrapper = css`
  display: flex;
  flex-direction: row;
`;

const inputWrapper = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  @media ${breakpoints.custom970} {
    flex-direction: row;
  }
`;

const messageInput = css`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const buttonWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const inputs = css`
  width: 100%;
  padding-top: var(--spacing-huge);
  @media ${breakpoints.custom970} {
    width: 45%;
    padding-top: 0;
  }
`;

const textAreaWrapper = css`
  margin-bottom: var(--spacing-xlrg);
`;

export const contactStyles = {
  root,
  pageTitle,
  chooseWrapper,
  inputWrapper,
  messageInput,
  buttonWrapper,
  inputs,
  textAreaWrapper,
};
