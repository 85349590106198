import { SerializedStyles } from '@emotion/utils';
import { Loading } from 'components';
import { Link } from 'gatsby';
import React from 'react';
import { buttonStyles } from 'style';

export type ButtonSizes = 'sml' | 'med' | 'lrg';

interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: any;
  to?: string;
  href?: string;
  variant?: 'primary' | 'secondary' | 'ghost' | 'light';
  size?: ButtonSizes;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  isLoading?: boolean;
  additionalClasses?: SerializedStyles;
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  icon,
  variant = 'primary',
  size = 'med',
  type = 'button',
  disabled = false,
  to,
  href,
  additionalClasses,
  isLoading = false,
  children,
}) => {
  function buttonType() {
    switch (variant) {
      case 'primary':
        return buttonStyles.primary;
      case 'secondary':
        return buttonStyles.secondary;
      case 'ghost':
        return buttonStyles.ghost;
      case 'light':
        return buttonStyles.light;
      default:
        return;
    }
  }

  function buttonSize() {
    switch (size) {
      case 'lrg':
        return buttonStyles.lrg;
      case 'med':
        return buttonStyles.med;
      case 'sml':
        return buttonStyles.sml;
      default:
        return;
    }
  }

  return to ? (
    <Link
      css={[buttonStyles.root, buttonType(), buttonSize(), additionalClasses]}
      to={to}
    >
      {children}
      <div css={{ paddingLeft: '8px' }}>{icon}</div>
    </Link>
  ) : href ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      css={[buttonStyles.root, buttonType(), buttonSize(), additionalClasses]}
    >
      {children}
      {icon && <div css={{ paddingLeft: '8px' }}>{icon}</div>}
    </a>
  ) : (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      css={[
        buttonStyles.root,
        buttonType(),
        buttonSize(),
        disabled && buttonStyles.disabled,
        additionalClasses,
      ]}
    >
      {
        <Loading isLoading={isLoading} inline={true}>
          {children}
          {icon && <div css={{ paddingLeft: '8px' }}>{icon}</div>}
        </Loading>
      }
    </button>
  );
};
