import { PageProps } from 'gatsby';
import { Footer, Header } from 'modules/navigation';
import React from 'react';
import { layoutStyles } from '../style/layout.styles';

export const Layout: React.FC<PageProps> = ({ children }) => {
  return (
    <div css={layoutStyles.pageWrapper}>
      <Header />
      <main css={layoutStyles.root}>{children}</main>
      <Footer />
    </div>
  );
};
