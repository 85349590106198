import { css } from '@emotion/react';

const root = css`
  border: 3px solid hsl(var(--color-secondary-2));
  border-top: 3px solid hsl(var(--color-white-11));
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const loadingStyles = { root };
