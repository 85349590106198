import { css } from '@emotion/react';
import { SerializedStyles } from '@emotion/utils';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { fontFamily } from 'style';

/**
 * Input base
 */
const input = css`
  padding-top: var(--spacing-med);
  padding-bottom: var(--spacing-med);

  display: inline-flex;
  width: 100%;

  cursor: pointer;
  white-space: nowrap;

  text-decoration: none;
  font-family: ${fontFamily.supreme};

  background: transparent;
  border: 1px solid ${color('borderLight', 0)};

  color: ${color('text', 2)};
  ${fontSize('base')};
  ${lineHeight('base')};

  transition: border-color 0.2s ease-out;

  &::placeholder {
    color: ${color('text', 2)};
  }

  &:hover {
    border-color: ${color('text', 2)};
    cursor: pointer;
  }

  &:focus {
    border-color: ${color('text', 2)};
    outline: none;
  }

  &[disabled] {
    background: gray;
    cursor: not-allowed;
  }
`;

const xsmall = css`
  ${input};
  height: calc(var(--unit) * 8) px;
`;

const small = css`
  ${input};
  height: calc(var(--unit) * 10) px;
`;

const normal = css`
  ${input};
  height: $calc(var(--unit) * 12) px;
`;

const textarea = css`
  box-sizing: border-box;

  width: 100%;
  resize: none;
  border: none;
  outline: none;
  ${input};
  height: 100%;

  white-space: pre-wrap;
`;

const inputError = css`
  ${input}

  border: 1px solid hsl(var(--color-warning-5));
`;

const createSizeMap = (style: SerializedStyles) => ({
  xsmall: css(style, xsmall),
  small: css(style, small),
  normal: css(style, normal),
});

export const inputStyles = {
  input: createSizeMap(input),
  textarea,
  inputError: createSizeMap(inputError),
};
