import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

/**
 * Field base
 */
const field = css`
  margin-bottom: var(--spacing-xlrg);
  position: relative;
  border-bottom: 1px solid ${color('borderLight', 9)};
`;

const fieldError = css`
  margin-bottom: var(--spacing-tny);
  position: relative;
  border-bottom: 1px solid hsl(var(--color-warning-5));
`;

const label = css`
  margin-bottom: var(--spacing-tny);
  display: block;

  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('tiny')};
  ${lineHeight('tiny')};

  color: ${color('text', 2)};
  opacity: 0.5;
`;

const textarea = css`
  height: 150px;

  @media ${breakpoints.medium} {
    height: 250px;
  }

  margin-bottom: var(--spacing-xlrg);
`;

const errorMsg = css`
  margin-bottom: var(--spacing-tny);
  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('tiny')};
  ${lineHeight('tiny')};

  color: hsl(var(--color-warning-5));
`;

export const fieldStyles = { field, label, textarea, errorMsg, fieldError };
