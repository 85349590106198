import React from 'react';

export const TwitterIcon: React.FC = () => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 1.91667C19.25 2.25 18.5 2.5 17.6667 2.58333C18.5 2.08333 19.1667 1.25 19.5 0.333333C18.6667 0.833333 17.8333 1.16667 16.9167 1.33333C16.1667 0.5 15.0833 0 13.9167 0C11.6667 0 9.83333 1.83333 9.83333 4.08333C9.83333 4.41667 9.83333 4.75 9.91667 5C6.41667 4.83333 3.41667 3.16667 1.41667 0.666667C1 1.33333 0.833333 2 0.833333 2.75C0.833333 4.16667 1.58333 5.41667 2.66667 6.16667C2 6.16667 1.33333 6 0.833333 5.66667C0.833333 5.66667 0.833333 5.66667 0.833333 5.75C0.833333 7.75 2.25 9.41667 4.08333 9.75C3.75 9.83333 3.41667 9.91667 3 9.91667C2.75 9.91667 2.5 9.91667 2.25 9.83333C2.75 11.5 4.25 12.6667 6.08333 12.6667C4.66667 13.75 2.91667 14.4167 1 14.4167C0.666667 14.4167 0.333333 14.4167 0 14.3333C1.83333 15.5 4 16.1667 6.25 16.1667C13.8333 16.1667 17.9167 9.91667 17.9167 4.5C17.9167 4.33333 17.9167 4.16667 17.9167 4C18.75 3.41667 19.4167 2.66667 20 1.91667Z"
      fill="white"
    />
  </svg>
);
