import { SerializedStyles } from '@emotion/utils';
import React from 'react';
import { layoutStyles } from 'style';

interface ContainerProps {
  className?: SerializedStyles;
}

export const Container: React.FC<ContainerProps> = ({
  children,
  className,
}) => {
  return <div css={className || layoutStyles.container}>{children}</div>;
};
