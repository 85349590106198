import { css } from '@emotion/react';
import { fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { flex } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily } from 'style';

const root = css`
  ${flex.display.inline};
  position: relative;
  user-select: none;
  cursor: pointer;
  border-style: solid;
  outline: inherit;
  text-decoration: none;
  border-width: 2px;
`;

const primary = css`
  color: hsl(var(--color-white-11));
  background-color: hsl(var(--color-primary-5));
  border-color: hsl(var(--color-primary-5));

  font-family: ${fontFamily.supremeMedium};
  ${fontSize('base')};
  ${lineHeight('base')}

  background: linear-gradient(to left,  hsl(var(--color-primary-5)) 50%, hsl(var(--color-primaryDark-4)) 50%) right;
  background-size: 200%;
  transition: 0.3s ease-out;

  background-repeat: no-repeat;

  path {
    stroke: hsl(var(--color-white-11));
  }

  &:hover {
    background-position: left;
    color: hsl(var(--color-white-11));
    border-color: hsl(var(--color-primaryDark-4));
    path {
      stroke: hsl(var(--color-white-11));
    }
  }
`;

const secondary = css`
  color: hsl(var(--color-white-11));
  border-color: hsl(var(--color-secondary-2));

  font-family: ${fontFamily.supremeMedium};
  ${fontSize('base')};
  ${lineHeight('base')}

  background: linear-gradient(to left, hsl(var(--color-secondary-2)) 50%, hsl(var(--color-secondaryDark-1)) 50%) right;
  background-size: 200%;
  transition: 0.3s ease-out;

  &:hover {
    background-position: left;
    color: hsl(var(--color-border-8));
    border-color: hsl(var(--color-secondaryDark-1));

    path {
      stroke: hsl(var(--color-border-8));
    }
  }
`;

const ghost = css`
  color: hsl(var(--color-primary-5));
  background-color: transparent;
  border-color: transparent;

  font-family: ${fontFamily.supremeMedium};
  ${fontSize('base')};
  ${lineHeight('base')}

  transition: all 0.3s ease-out;

  &:hover {
    color: hsl(var(--color-white-11));
    border-color: hsl(var(--color-primaryDark-4));
  }
`;

const light = css`
  color: hsl(var(--color-primary-5));
  background-color: transparent;
  border-color: hsl(var(--color-primary-5));

  font-family:  ${fontFamily.supreme};
  font-size: ${fontSize('base')};
  line-height: ${lineHeight('base')}

  background: linear-gradient(to left, transparent 50%, hsl(var(--color-primaryDark-4)) 50%) right;
  background-size: 200%;
  transition: all .3s ease-out;

  &:hover {
    background-position: left;
    color: hsl(var(--color-white-11));
    border-color: hsl(var(--color-primaryDark-4));

    path {
      stroke: hsl(var(--color-white-11));
    }
  }
`;

const sml = css`
  padding: var(--spacing-tny) calc(var(--unit) * 6);
`;

const med = css`
  padding: var(--spacing-sml) calc(var(--unit) * 7);
`;

const lrg = css`
  padding: var(--spacing-lrg) calc(var(--unit) * 8);
`;

const disabled = css`
  opacity: 0.3;
  pointer-events: none;
`;

export const buttonStyles = {
  root,
  primary,
  secondary,
  ghost,
  light,
  sml,
  med,
  lrg,
  disabled,
};
