import { GumGridConfig } from '@prototyp/gatsby-plugin-gumball';

/**
 * Unitless unit value (in px)
 */
export const unit = 4;

/**
 * Color HEX values (to be converted to HSL)
 */
export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  primary: '#05D095',
  primaryDark: '#21A982',
  secondary: '#004934',
  secondaryDark: '#001B13',
  tertiary: '#F77F00',
  text: '#242424',
  textLight: '#4A4A4A',
  success: '#259A51',
  warning: '#C32424',
  note: '#E9AD39',
  bgLight: '#F5F5F5',
  bgDark: '#D2D2E1',
  border: '#B9B9B9',
  borderLight: '#D5D5D5',
  borderDark: '#727272',
};

/**
 * Unitless px value pairs [font-size, line-height]
 */
export const typography = {
  tiny: [12, 16],
  small: [14, 20],
  base: [16, 24],
  medium: [20, 28],
  large: [24, 36],
  sLarge: [28, 38],
  xLarge: [32, 40],
  xxLarge: [40, 48],
  xxxLarge: [48, 50],
  huge: [60, 60],
};

/**
 * Minimum and maximum container widths, userd for fluid typography.
 * Max value is the total width of the page design (outer width of the page).
 */
export const minContainerWidth = 320;
export const maxContainerWidth = 1600;

export const fontWeight = {
  thin: 100,
  light: 300,
  regular: 400,
  semiBold: 500,
  bold: 700,
  black: 900,
};

export const borderRadius = {
  regular: 'var(--unit)',
  full: '50%',
};

export const fontFamily = {
  supreme: 'Supreme-Regular',
  supremeBold: 'Supreme-Bold',
  supremeMedium: 'Supreme-Medium',
  supremeItalic: 'Supreme-Italic',
};

/**
 * Unitless z-index values, ordered from low to high
 */
export const zIndices = [-1, 0, 1, 2, 3];

/**
 * Breakpoint values without @media tags
 */

export const breakpoints = {
  small: 'screen and (min-width: 420px)',
  custom600: 'screen and (min-width: 600px)',
  medium: 'screen and (min-width: 768px)',
  custom970: 'screen and (min-width: 970px)',
  custom800: 'screen and (min-width: 800px)',
  custom1024: 'screen and (min-width: 1024px)',
  large: 'screen and (min-width: 1280px)',
  xLarge: 'screen and (min-width: 1920px)',
};

/**
 * Wrappers
 */

export const wrappers = {
  tny: '320px',
  sml: '568px',
  med: '768px',
  large: '1280px',
  page: '1440px',
};

/**
 * Spacing - margins, paddings, grid gaps
 */
export const spacing = {
  tny: 'calc(var(--unit) * 1)',
  sml: 'calc(var(--unit) * 2)',
  med: 'calc(var(--unit) * 3)',
  lrg: 'calc(var(--unit) * 4)',
  xlrg: 'calc(var(--unit) * 5)',
  xxlrg: 'calc(var(--unit) * 6)',
  xxxlrg: 'calc(var(--unit) * 10)',
  huge: 'calc(var(--unit) * 20)',
};

/**
 * Main grid - page layout
 */

export const pageGrid: GumGridConfig[] = [
  {
    cols: 4,
    gap: { column: 20 },
  },
  {
    mediaMinWidth: 'medium',
    cols: 8,
    gap: { column: 28 },
  },
  {
    mediaMinWidth: 'large',
    cols: 12,
    gap: { column: 32 },
  },
];

/**
 * Additional grids - component
 */
export const componentGrids = {
  cardGrid: [
    {
      cols: 1,
      gap: { row: 18 },
    },
    {
      mediaMinWidth: 'medium',
      cols: 2,
      gap: { column: 62 },
    },
    {
      mediaMinWidth: 'large',
      cols: 2,
      gap: { column: 124 },
    },
  ],

  joinGrid: [
    {
      cols: 1,
      gap: { row: 18 },
    },
    { mediaMinWidth: 'custom600', cols: 2, gap: { column: 30 } },
    {
      mediaMinWidth: 'medium',
      cols: 2,
      gap: { column: 62 },
    },
    {
      mediaMinWidth: 'large',
      cols: 2,
      gap: { column: 124 },
    },
  ],

  growGrid: [
    {
      cols: 1,
      gap: { row: 18 },
    },
    {
      mediaMinWidth: 'medium',
      cols: 1,
      gap: { column: 62 },
    },
    {
      mediaMinWidth: 'custom1024',
      cols: 2,
      gap: { column: 124 },
    },
  ],
};

export const customPalette = {};
