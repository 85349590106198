import { SerializedStyles } from '@emotion/utils';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext, ValidationRules } from 'react-hook-form';
import { fieldStyles, inputStyles } from 'style';

interface Props {
  name: string;
  label: string;
  size?: 'xsmall' | 'small' | 'normal';
  defaultValue?: string;
  placeholder?: string;
  textarea?: boolean;
  disabled?: boolean;
  validationObject?: ValidationRules;
  className?: SerializedStyles;
  onChange?: (value: string) => void;
}

export const ValidatedInput: React.FC<Props> = ({
  name,
  label,
  size = 'normal',
  defaultValue,
  placeholder,
  textarea,
  disabled,
  validationObject,
  className,
  onChange,
}) => {
  const { register, errors, trigger } = useFormContext();

  let delayDebounceFn: NodeJS.Timeout;

  return (
    <>
      <div
        css={
          className || (errors && errors[name])
            ? fieldStyles.fieldError
            : fieldStyles.field
        }
      >
        <label css={fieldStyles.label} htmlFor={name}>
          {label}
        </label>
        {!textarea && (
          <input
            type="text"
            id={name}
            name={name}
            defaultValue={defaultValue}
            ref={validationObject ? register(validationObject) : register}
            css={
              errors && errors[name]
                ? inputStyles.inputError[size || 'normal']
                : inputStyles.input[size || 'normal']
            }
            placeholder={placeholder}
            disabled={disabled}
            onChange={handleInputChange}
          />
        )}

        {textarea && (
          <div css={fieldStyles.textarea}>
            <textarea
              name={name}
              id={name}
              placeholder={placeholder}
              ref={validationObject ? register(validationObject) : register}
              css={
                errors && errors[name]
                  ? inputStyles.textarea
                  : inputStyles.textarea
              }
              disabled={disabled}
              defaultValue={defaultValue}
            />
          </div>
        )}
      </div>
      {errors && errors[name] ? (
        <ErrorMessage
          errors={errors}
          name={name}
          as={<p css={fieldStyles.errorMsg} />}
        >
          {({
            messages,
          }: {
            messages: Array<{ type: string; message: string }>;
          }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <p key={type}>{message}</p>
            ))
          }
        </ErrorMessage>
      ) : (
        <p css={fieldStyles.errorMsg}>{''}</p>
      )}
    </>
  );

  function handleInputChange(event: React.FormEvent<HTMLInputElement>) {
    clearTimeout(delayDebounceFn);

    if (onChange) onChange(event.currentTarget.value);

    if (errors[name]) {
      trigger(name);
    }

    delayDebounceFn = setTimeout(() => {
      trigger(name);
    }, 1200);
  }
};
