import { css } from '@emotion/react';
import { color } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
const root = css`
  z-index: 4;
  position: relative;
  transition: all 0.5s;
  background-color: hsl(var(--color-white-11));
`;

const grayBg = css`
  background-color: hsl(var(--color-bgLight-10));
`;

const navOpen = css`
  width: 40px;
  height: 40px;
  border: none;
  padding: 0;
  outline: inherit;

  cursor: pointer;
  touch-action: manipulation;

  align-items: center;

  height: 40px;
  width: 40px;

  background-color: inherit;

  &:focus {
    outline: 0;
  }

  @media ${breakpoints.medium} {
    display: none;
  }
`;

const hamburger = css`
  width: 100%;
  height: 2px;
  background-color: hsl(var(--color-text-2));
  margin: 6px 0;
  transition: all 0.3s ease-out;
`;

const lineDown = css`
  -webkit-transform: rotate(-45deg) translate(-6px, 5px);
  -moz-transform: rotate(-45deg) translate(-6px, 5px);
  -ms-transform: rotate(-45deg) translate(-6px, 5px);
  -o-transform: rotate(-45deg) translate(-6px, 5px);
  transform: rotate(-45deg) translate(-6px, 5px);
`;

const lineUp = css`
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  -moz-transform: rotate(45deg) translate(-6px, -6px);
  -ms-transform: rotate(45deg) translate(-6px, -6px);
  -o-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
`;

const lineDissapear = css`
  opacity: 0;
`;

const logo = css`
  padding: 24px 0;
`;

const wrapper = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const headerStyles = {
  root,
  grayBg,
  navOpen,
  lineDown,
  hamburger,
  lineDissapear,
  lineUp,
  logo,
  wrapper,
};
