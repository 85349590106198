import React from 'react';
import { horizontalLineStyles } from 'style';

interface Props {
  isActive: boolean;
}

export const HorizontalLine: React.FC<Props> = ({ isActive }) => {
  return (
    <div css={horizontalLineStyles.content}>
      <div css={horizontalLineStyles.line(isActive)} />
    </div>
  );
};
