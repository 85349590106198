import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const sectionTitle = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('xLarge')};
  ${lineHeight('xLarge')};

  color: hsl(var(--color-black-1));

  padding-bottom: var(--spacing-xlrg);

  @media ${breakpoints.medium} {
    ${fontSize('xxLarge')};
    ${lineHeight('xxLarge')};
  }

  @media ${breakpoints.large} {
    padding-bottom: var(--spacing-xxxlrg);

    ${fontSize('xxxLarge')};
    ${lineHeight('xxxLarge')};
  }
`;

const title = css`
  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('medium')};
  ${lineHeight('medium')};
  color: hsl(var(--color-black-1));

  padding-bottom: var(--spacing-xxxlrg);

  @media ${breakpoints.large} {
    padding-bottom: var(--spacing-xlrg);
  }
`;

const text = css`
  font-family: ${fontFamily.supreme};
  font-weight: ${fontWeight.regular};

  ${fontSize('base')};
  ${lineHeight('base')};

  color: hsl(var(--color-black-1));
`;

const link = css`
  color: ${color('primary', 5)};
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    width: 0rem;
    transition: width 0.5s ease;
    margin-top: 20px;
  }
  &:hover {
    transition: 0.5s;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      height: 2px;
      width: 100%;
      background-color: ${color('primary', 5)};
    }
  }
`;

const wrapper = css`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const staticStyles = { sectionTitle, title, text, link, wrapper };
