import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const root = css`
  display: none;

  @media ${breakpoints.medium} {
    display: flex;
  }
`;

const visible = css``;

const list = css`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-inline-start: 0;

  list-style-type: none;
`;

const navLink = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('small')};
  ${lineHeight('small')};
  color: ${color('text', 2)};
  text-decoration: none;

  padding: 24px var(--spacing-xlrg);

  width: fit-content;

  position: relative;

  &.active {
    color: hsl(var(--color-primary-5));
  }

  &::after {
    content: '';
    width: 0rem;
    transition: width 0.5s ease;
    margin-top: 56px;
  }

  &:hover {
    transition: 0.5s;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 12px;
      height: 2px;
      width: 100%;
      background-color: ${color('primary', 5)};
    }
  }
`;

const donate = css`
  margin-left: 25px;
  margin-right: 25px;
`;

export const navigationStyles = { root, visible, list, navLink, donate };
