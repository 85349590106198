import { css } from '@emotion/react';
import SupremeRegularWoff2 from 'static/fonts/fonts/Supreme-Regular.woff2';
import SupremeRegular from 'static/fonts/fonts/Supreme-Regular.woff';
import SupremeItalicWoff2 from 'static/fonts/fonts/Supreme-Italic.woff2';
import SupremeItalic from 'static/fonts/fonts/Supreme-Italic.woff';
import SupremeMediumWoff2 from 'static/fonts/fonts/Supreme-Medium.woff2';
import SupremeMedium from 'static/fonts/fonts/Supreme-Medium.woff';
import SupremeBoldWoff2 from 'static/fonts/fonts/Supreme-Bold.woff2';
import SupremeBold from 'static/fonts/fonts/Supreme-Bold.woff';

export const global = css`
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  h1 {
    font-weight: 400;
  }

  h2,
  h3 {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  @font-face {
    font-family: 'Supreme-Regular';
    src: url(${SupremeRegularWoff2}) format('woff2'),
      url(${SupremeRegular}) format('woff');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Supreme-Italic';
    src: url(${SupremeItalicWoff2}) format('woff2'),
      url(${SupremeItalic}) format('woff');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Supreme-Medium';
    src: url(${SupremeMediumWoff2}) format('woff2'),
      url(${SupremeMedium}) format('woff');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Supreme-Bold';
    src: url(${SupremeBoldWoff2}) format('woff2'),
      url(${SupremeBold}) format('woff');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }
`;
