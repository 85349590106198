import { css } from '@emotion/react';
import { color, fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { fontFamily, fontWeight } from 'style';

const root = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: var(--level-3);
  background-color: hsl(var(--color-white-11));
  margin-top: 72px;

  @media ${breakpoints.medium} {
    display: none;
  }
`;

const list = css`
  display: flex;
  flex-direction: column;

  padding-inline-start: 0;

  list-style-type: none;
  padding: var(--spacing-xxxlrg) var(--spacing-xlrg);
`;

const navLink = css`
  font-family: ${fontFamily.supremeMedium};
  font-weight: ${fontWeight.regular};

  ${fontSize('xLarge')};
  ${lineHeight('xLarge')};
  color: ${color('text', 2)};
  text-decoration: none;

  width: fit-content;

  position: relative;

  &.active {
    color: hsl(var(--color-primary-5));
  }
`;

const link = css`
  padding: var(--spacing-xlrg) 0;
`;

export const mobileNavigationStyles = { root, list, navLink, link };
