/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import defaultSeoImage from 'assets/images/Hero.png';

import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  image?: string;
  description?: string;
  lang?: string;
  meta?: Array<unknown>;
  title: string;
  url?: string;
  siteName?: string;
  creator?: string;
}

export const SEO: React.FC<Props> = ({
  description = 'The most functional prosthetic hand in the world.',
  lang = 'en',
  meta = [],
  title,
  image = `https://www.makerhand.com${defaultSeoImage}`,
  url,
  creator,
  siteName = 'MakerHand',
}) => (
  <Helmet
    htmlAttributes={{
      lang,
    }}
    link={[
      {
        rel: 'canonical',
        href: url,
      },
    ]}
    title={title}
    titleTemplate={`%s | MakerHand`}
    meta={[
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        property: `og:url`,
        content: url,
      },
      {
        property: `og:image`,
        content: image,
      },
      {
        name: `og:image`,
        content: image,
      },
      {
        name: `image`,
        property: `og:image`,
        content: image,
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description,
      },
      {
        name: `twitter:creator`,
        content: creator,
      },
      {
        name: `twitter:image`,
        content: image,
      },
      {
        property: `og:site_name`,
        content: siteName,
      },
      {
        name: `og:site_name`,
        content: siteName,
      },
    ].concat(meta)}
  />
);
